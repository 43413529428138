import React from "react";

import Layout from '../components/layout';
import SocialIcons from '../components/SocialIcons';
import Head from '../components/head';

import {
    ContactContainer
} from '../Styles/Contact.style';

const Contact = () => {
    return (
        <Layout>
            <Head title="Contact" />
            <ContactContainer>
                <h3>Let's Connect!</h3>
                <p>Feel free to reach out if you're looking for a developer, have a question, or just want to connect.</p>
                <div><SocialIcons /></div>
            </ContactContainer>
        </Layout>
    );
}

export default Contact;