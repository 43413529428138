import styled from "styled-components";

export const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;


    text-align: center;

    h3 {
        font-size: 20px;
        font-weight: 600;
    }

    p {
        font-size: 16px;
        margin-bottom: 30px;
    }

    div {
        justify-content: center;
    }

`;
